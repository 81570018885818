<template>
  <div
    class="subheader py-2 py-lg-4"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
   
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <h5 class="text-dark font-weight-bold my-2 mr-5">
          {{ $t(title) }}
        </h5>
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-item">
            <router-link :to="'/clients'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in breadcrumbs">
            <li class="breadcrumb-item" :key="`${i}-${breadcrumb.id}`">
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ $t(breadcrumb.title) }}
              </router-link>
              <span class="text-muted" :key="i" v-if="!breadcrumb.route">
                {{ $t(breadcrumb.title) }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      
      
      <div class="d-flex align-items-center">
        <a href="#" @click.prevent='logout' class="btn btn-light font-weight-bold btn-sm">
          {{$t('logout')}}
        </a>
        <a href="#" @click.prevent='lang' class="btn btn-primary font-weight-bold btn-sm ml-5">
           {{$t('lang')}}
        </a>
      </div>
      
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";


export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String
  },
  methods: {
    logout() {
        this.$store.dispatch(LOGOUT);
        this.$router.push({name: 'login'});
    },
    lang(){
      //console.log(this.$t('lang'))
      //console.log(localStorage.getItem('lang'))
      window.location.reload();
      if(localStorage.getItem('lang')==null){
        localStorage.setItem('lang','en')
      }
      if(localStorage.getItem('lang') == 'en'){
        localStorage.setItem('lang','ar')
      }
      else
       if(localStorage.getItem('lang') == 'ar'){
        localStorage.setItem('lang','en')
      }
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  }
};
</script>

<style lang="css" scoped>
  .header-fixed.subheader-fixed .subheader {
    top: 0;
  }
</style>